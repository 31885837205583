
import UspCard from /* webpackChunkName: "UspCard" */ '../../../components/ui/UspCard'

export default {
  name: 'ConversionGuide',
  components: {
    UspCard
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    content: {
      default: null,
      required: true
    }
  },
  computed: {
    isMobile () { return this.$store.state.store.isMobile }
  }
}
