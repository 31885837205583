
import { resetAds } from "~/fc/Ads";

export default {
  name: 'AdInPage',
  props: {
    full: {
      required: false,
      default: false
    },
    position: {
      required: false,
      default: 'top'
    }
  },
  computed: {
    freeMember () {
      return !this.$store.state.subscription.loaded || this.$store.state.subscription.name === undefined || this.$store.state.subscription.name === 'Free'
    },
    downloadPage () {
      return this.$route.fullPath.includes('/download')
    },
    topAdUnitIdentifier () {
      return this.downloadPage 
        ? '/22404391699/FreeConvert_Download_TopLeaderboard'
        : '/22404391699/FreeConvert_TopLeaderboard'
    },
    bottomAdUnitIdentifier () {
      return this.downloadPage 
        ? '/22404391699/FreeConvert_Download_BottomLeaderboard'
        : '/22404391699/FreeConvert_BottomLeaderboard'
    }
  },
  mounted () {
    this.$bus.$on('LOGOUT', () => {
      this.adInPageKey++
      setTimeout(() => { resetAds() }, 200)
    })
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        resetAds()
      });
    })
  },
  methods: {
    runAdScript () {
      try {
        const fusetag = window.fusetag || (window.fusetag = { que: [] })
        fusetag.registerZone('22845353457')
      } catch (e) {
      }
    }
  }
}
