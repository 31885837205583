import HttpService from '../HttpService'

export async function getPageInfo(id, ctx, tools = false) {
  const params = {}
  if (tools) { params.tools = true }
  if (ctx.app.i18n && ctx.app.i18n.locale) { params.lang = ctx.app.i18n.locale }
  try {
    const { data: info } = await HttpService.query().setPath('/view/pages/' + id).setParams(params).get()
    return info
  } catch (e) {
  }
}

export async function getUnitConverterPageInfo(id, ctx) {
  try {
    const params = {}
    if (ctx.app.i18n && ctx.app.i18n.locale) { params.lang = ctx.app.i18n.locale }
    const { data: info } = await HttpService.query().setPath('/view/convert/' + id).setParams(params).get()
    return info
  } catch (e) { }
}

export async function getUnitTypePageInfo(path, app) {
  try {
    const params = {}
    if (app.i18n && app.i18n.locale) { params.lang = app.i18n.locale }
    const { data: info } = await HttpService.query().setPath('/view/' + path).setParams(params).get()
    return info
  } catch (e) { }
}

export async function getSiteNews(ctx) {
  const { data } = await HttpService.query().setPath('/sitenews').get()

  return data
    ? data.sort(function (a, b) {
      const date1 = a.date_string ? a.date_string.replace('th', '').replace('st', '').replace('nd', '').replace('rd', '').replace(',', '') : ''
      const date2 = b.date_string ? b.date_string.replace('th', '').replace('st', '').replace('nd', '').replace('rd', '').replace(',', '') : ''
      return new Date(date2) - new Date(date1)
    })
    : []
}

export async function getUnitPageInfo(ctx) {
  const id = 'cm-to-inches'
  const type = 'Unit'
  const active = 'UnitIndex'
  const params = {}
  if (ctx.app.i18n && ctx.app.i18n.locale) { params.lang = ctx.app.i18n.locale }
  const { data } = await HttpService.query().setPath('/view/convert/' + id).setParams(params).get()
  const info = { type, ...data, active }

  ctx.store.commit('updateInfo', info)
  return info
}
