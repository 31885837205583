import { handleAdTeamDirection } from "./TelemetryEvents"
export default class UrlParamHandler {
    constructor(route) {
        this.route = route
    }

    checkIfAdTeamDirection () {
        const campaign = this.route.query.utm_campaign
        if(!campaign) return false
        return campaign.includes('asc')
    }

    handle () {
        if (this.checkIfAdTeamDirection()) handleAdTeamDirection()
    }
}