function checkIfTelemetryUser () {
    return $nuxt.$store.state.store.telemetryUser
}
/**
 * details - https://freeconvert.atlassian.net/browse/FC-4919 
 * @param {*} event 
 * @param {*} data
 */
export function sentTelemetryPaymentEvent (event, data) {
    try {
        if (!checkIfTelemetryUser()) return
        var params = { en: event,  ...data }
        bydata.sendPackagePaymentDetails(params);
    } catch (e) {
        console.error('Error while executing bydata.sendPackagePaymentDetails', e)
    }
}

export function handleTelemetryExpiry() {
    const telemetryUserTimeStamp = localStorage.getItem("TelemetryUser");
    const dateNow = new Date();
    const dateExpiry = new Date(parseInt(telemetryUserTimeStamp));
    if (dateExpiry >= dateNow)
        $nuxt.$store.commit("store/setTelemetryUser", true);
    else {
        localStorage.removeItem('TelemetryUser');
        $nuxt.$store.commit('store/setTelemetryUser', false);
    }
}

export function handleAdTeamDirection() {
    const exitingTimer = localStorage.getItem('TelemetryUser')
    if(exitingTimer) return;

    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30); // Add 30 days directly to expiryDate
    // expiryDate.setTime(expiryDate.getTime() + 5 * 60 * 1000); // Add 5 days to expiryDate for testing
    
    const expiryTimestamp = expiryDate.getTime();
    localStorage.setItem('TelemetryUser', expiryTimestamp);
    $nuxt.$store.commit('store/setTelemetryUser', true);
}
