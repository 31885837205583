
import { SettingsIcon } from 'vue-feather-icons'
import Accordion from /* webpackChunkName: "AccordionSingle" */ "../../components/ui/AccordionSingle";
import AdvancedOptions from /* webpackChunkName: "AdvancedOptions" */ "~/components/file-io/AdvancedOptions";
import FileInput from /* webpackChunkName: "FileInput" */ '~/components/file-io/FileInput';
import ThumbList from /* webpackChunkName: "ThumbList" */ "~/components/file-io/file/ThumbList";

export default {
  name: 'ToolTemplate',
  components: {
    SettingsIcon,
    FileInput,
    Accordion,
    AdvancedOptions,
    ThumbList,
    BannerContainer: () => import( /* webpackChunkName: "Banner" */ '~/components/ui/BannerContainer'),
    PreviewLayout: () => import(/* webpackChunkName: "PreviewLayout" */ "./PreviewLayout"),
  },
  computed: {
    info () { return this.$store.state.info },
    list() { return this.$store.state.list },
    hasFiles () { return !!this.$store.state.list.length },
    merging () { return this.$store.state.advancedSetting.merging },
    pageUid() { return this.merging ? 'root' : 'page' },
    customVariables() { return this.info.customVariables },
    enablePreview() { return this.customVariables ? this.customVariables.enablePreview : false },
    enableSort() { return this.customVariables ? this.customVariables.enableSort : false },
    selectedPreviewItem () { return this.$store.state.advancedSetting.selectedPreviewItem },
    slug() {
      if (this.merging) return this.merging;
      if (this.info.slug) return this.info.slug;
      return this.info.source.ext + "-to-" + this.info.target.ext;
    },
    pageOptionList() {
      return this.slug && this.$store.state.advancedSetting.perConversionType[this.slug] &&
              this.$store.state.advancedSetting.perConversionType[this.slug].list
        ? this.$store.state.advancedSetting.perConversionType[this.slug].list
        : []
    },
    showAdvancedOptions() {
      // preview is enabled, don't show extra advanced-options
      if (this.enablePreview) { return false }
      if (this.hasFiles) return this.merging
      return this.info.advancedOptions && this.pageOptionList.length
    }
  },
  created () {
    this.$store.commit('updateBreadcrumbs', [{
      url: this.$route.path,
      label: this.info.header_h1
    }])
  },
  mounted () {
    if (this.info.target) {
      this.$store.dispatch("advancedSetting/setOptions", {
        slug: this.info.slug,
        list: this.info.advancedOptions,
        lang: this.$i18n.locale,
        targetExt: this.info.target,
      });

      this.$store.dispatch(
        "advancedSetting/setDefaultTarget",
        this.info.target
      );
    } else {
      this.$store.dispatch('advancedSetting/setDefaultTarget', '')
    }
    if (this.info.customVariables && this.info.customVariables.forceTarget) {
      this.$store.commit('forceTarget', this.info.target)
    }
  },
  methods: {
    convertFiles() {
      this.$store.commit("setList", this.$refs.thumbList.sortedList);
      this.$bus.$emit("goToDownloadPage");
    },
  }
}
