class InfoParser {
  constructor (ctx) {
    this.context = ctx
    this.info = this.context ? this.context.$store.state.info : null
  }

  getAllowedList () {
    if (!this.info) { return '' }
    if (this.info.customVariables && this.info.customVariables.allowedExts) { return this.info.customVariables.allowedExts }
    if (this.info.limitAcceptedFiles && this.info.allowedArr) { return this.info.allowedArr.join(',') }
    return ''
  }

  uploadLimitMax () {
    if (!this.info.upload_limit) { return this.context.$store.state.subscription.plan.maxFileSizeInGb + 'GB' }
    if (!this.info.upload_limit.includes(',')) { return this.info.upload_limit }

    const ids = this.info.upload_limit.split(',')
    return ids[0]
  }

  sizeLimit () {
    const uploadLimitMax = this.uploadLimitMax()
    return parseFloat(uploadLimitMax.replace('GB', '')) * 1024 * 1024 * (uploadLimitMax.includes('GB') ? 1024 : 1)
  }

  getConversionGuide (info) {
    if (!info.main_card_text) { return null }
    const contentMarkUp = info.main_card_text[0] ? info.main_card_text[0].content : null
    const contents = Array.isArray(info.main_card_text) ? info.main_card_text.find(card => ('contents' in card)) : null
    const contentsMarkUp = contents ? contents.contents : null
    if (!contentMarkUp && !contentsMarkUp) { return null }
    const uspImages = [
      '<svg id="Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 70.5" height="87" width="70.5"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 3.75C6.43629 3.75 3.75 6.43629 3.75 9.75V62.25C3.75 65.5637 6.43629 68.25 9.75 68.25H62.25C65.5637 68.25 68.25 65.5637 68.25 62.25V9.75C68.25 6.43629 65.5637 3.75 62.25 3.75H9.75ZM0.75 9.75C0.75 4.77944 4.77944 0.75 9.75 0.75H62.25C67.2206 0.75 71.25 4.77944 71.25 9.75V62.25C71.25 67.2206 67.2206 71.25 62.25 71.25H9.75C4.77944 71.25 0.75 67.2206 0.75 62.25V9.75Z" fill="#FFFFFF"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M22.875 18.75C20.5969 18.75 18.75 20.5968 18.75 22.875C18.75 25.1532 20.5969 27 22.875 27C25.1532 27 27 25.1532 27 22.875C27 20.5968 25.1532 18.75 22.875 18.75ZM15.75 22.875C15.75 18.94 18.94 15.75 22.875 15.75C26.8101 15.75 30 18.94 30 22.875C30 26.81 26.8101 30 22.875 30C18.94 30 15.75 26.81 15.75 22.875Z" fill="#FFFFFF"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M49.9393 27.4393C50.5251 26.8535 51.4748 26.8535 52.0606 27.4393L70.8106 46.1893C71.3964 46.7751 71.3964 47.7249 70.8106 48.3106C70.2248 48.8964 69.2751 48.8964 68.6893 48.3106L51 30.6213L10.8106 70.8106C10.2248 71.3964 9.27509 71.3964 8.68931 70.8106C8.10352 70.2249 8.10352 69.2751 8.68931 68.6893L49.9393 27.4393Z" fill="#FFFFFF"></path></g></svg>',
      '<svg id="Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 70.5" height="87" width="70.5"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M28 3.25C14.331 3.25 3.25 14.331 3.25 28C3.25 41.669 14.331 52.75 28 52.75C41.669 52.75 52.75 41.669 52.75 28C52.75 14.331 41.669 3.25 28 3.25ZM0.25 28C0.25 12.6741 12.6741 0.25 28 0.25C43.3259 0.25 55.75 12.6741 55.75 28C55.75 43.3259 43.3259 55.75 28 55.75C12.6741 55.75 0.25 43.3259 0.25 28Z" fill="#FFFFFF"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0152 48.563C42.8364 48.4541 43.5905 49.0315 43.6995 49.8527L48.237 84.0527C48.3127 84.6237 48.0549 85.1876 47.5735 85.5038C47.0921 85.82 46.4721 85.8326 45.9783 85.5363L28 74.7493L10.0217 85.5363C9.52783 85.8326 8.90786 85.8199 8.42644 85.5037C7.94503 85.1875 7.68722 84.6235 7.76306 84.0525L12.3006 49.89C12.4096 49.0688 13.1638 48.4915 13.985 48.6006C14.8062 48.7096 15.3835 49.4638 15.2744 50.285L11.1467 81.3627L27.2283 71.7138C27.7033 71.4288 28.2967 71.4288 28.7717 71.7138L44.8538 81.363L40.7255 50.2473C40.6166 49.4261 41.194 48.672 42.0152 48.563Z" fill="#FFFFFF"></path></g></svg>',
      '<svg id="Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 70.5" height="87" width="70.5"><path fill-rule="evenodd" clip-rule="evenodd" d="M31.4733 0.0955062C31.8129 -0.0318354 32.1871 -0.0318354 32.5267 0.0955062L62.5267 11.3455C63.1121 11.5651 63.5 12.1247 63.5 12.75V39C63.5 50.9197 55.5774 60.6754 47.9878 67.3164C44.1614 70.6644 40.3422 73.2894 37.4825 75.0767C36.0511 75.9713 34.8561 76.6585 34.0155 77.1239C33.5952 77.3566 33.2631 77.5339 33.0342 77.6541C32.9197 77.7143 32.8309 77.7601 32.7698 77.7914L32.6989 77.8275L32.6795 77.8373L32.6738 77.8402L32.672 77.8411C32.672 77.8411 32.6708 77.8416 32 76.5C31.3292 77.8416 31.328 77.8411 31.328 77.8411L31.3262 77.8402L31.3206 77.8373L31.3011 77.8275L31.2302 77.7914C31.1691 77.7601 31.0803 77.7143 30.9658 77.6541C30.7369 77.5339 30.4048 77.3566 29.9845 77.1239C29.1439 76.6585 27.9489 75.9713 26.5175 75.0767C23.6578 73.2894 19.8386 70.6644 16.0122 67.3164C8.42262 60.6754 0.5 50.9197 0.5 39V12.75C0.5 12.1247 0.887859 11.5651 1.47331 11.3455L31.4733 0.0955062ZM32 76.5L31.3292 77.8416C31.7515 78.0528 32.2485 78.0528 32.6708 77.8416L32 76.5ZM32 74.8063C32.1612 74.7196 32.3495 74.6171 32.5626 74.4992C33.3626 74.0563 34.5114 73.3959 35.8925 72.5327C38.6578 70.8044 42.3386 68.2731 46.0122 65.0586C53.4226 58.5746 60.5 49.5803 60.5 39V13.7895L32 3.102L3.5 13.7895V39C3.5 49.5803 10.5774 58.5746 17.9878 65.0586C21.6614 68.2731 25.3422 70.8044 28.1075 72.5327C29.4886 73.3959 30.6374 74.0563 31.4374 74.4992C31.6505 74.6171 31.8388 74.7196 32 74.8063Z" fill="#FFFFFF"></path></svg>']

    const usps = contentsMarkUp
      ? contentsMarkUp.map((node, index) => ({
        content: node.content ? node.content : '',
        icon: node.icon ? node.icon : uspImages[index]
      }))
      : []

    return {
      instructions: contentMarkUp,
      usps
    }
  }

  getExtraContent () {
    const extra = []
    if (Array.isArray(this.info.main_card_text)) {
      this.info.main_card_text.filter((item) => { return (item.content) }).forEach((item, index) => {
        if (index > 0) { extra.push(item) }
      })
    }
    return extra
  }

  getSpecificToolsContent () {
    return this.info.list1
      ? this.info.list1.map(item => ({
        type: 'link',
        text: item.label,
        link: '/' + item.slug
      }))
      : []
  }

  getOtherToolsContent () {
    return this.info.list2
      ? this.info.list2.map(item => ({
        type: 'link',
        text: item.label,
        link: '/' + item.slug
      }))
      : []
  }

  getRelatedToolsContent () {
    return this.info.relatedTools
      ? this.info.relatedTools.map(item => ({
        type: 'link',
        text: item.name,
        link: (item.isRoot ? '/' : '/convert/') + item.slug
      }))
      : []
  }

  getTimezoneDisplayList (zones, app) {
    return zones
      ? zones.map(item => ({
        type: 'link',
        text: item.label.replace(' to ', (' ' + app.$t('to') + ' ')),
        link: app.localePath(item.url)
      }))
      : []
  }
}

export default InfoParser
