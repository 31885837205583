
import { SettingsIcon } from "vue-feather-icons";
import FileInput from /* webpackChunkName: "FileInput" */ "~/components/file-io/FileInput";

export default {
  name: "GroupTemplate",
  components: {
    SettingsIcon,
    FileInput,
    AdvancedOptions: () => import(/* webpackChunkName: "AdvancedOptions" */ "~/components/file-io/AdvancedOptions"),
    Accordion: () => import(/* webpackChunkName: "AccordionSingle" */ "~/components/ui/AccordionSingle")
  },
  computed: {
    info() { return this.$store.state.info },
    list() { return this.$store.state.list },
    hasFiles() { return !!this.$store.state.list.length },
    merging() { return this.$store.state.advancedSetting.merging },
    pageUid() { return this.merging ? 'root' : 'page' },
    slug() {
      if (this.merging) return this.merging;
      if (this.info.slug) return this.info.slug;
      return this.info.source.ext + "-to-" + this.info.target.ext;
    },
    pageOptionList() {
      return this.slug && this.$store.state.advancedSetting.perConversionType[this.slug] &&
              this.$store.state.advancedSetting.perConversionType[this.slug].list
        ? this.$store.state.advancedSetting.perConversionType[this.slug].list
        : []
    },
    showAdvancedOptions() {
      if (this.hasFiles) return this.merging
      return this.info.advancedOptions && this.pageOptionList.length
    }
  },
  created() {
    if (this.info.target || this.info.target.slug) {
      this.$store.dispatch(
        "advancedSetting/setDefaultTarget",
        this.info.target.slug
      );
    } else {
      this.$store.dispatch("advancedSetting/setDefaultTarget", "");
    }

    this.$store.commit("updateBreadcrumbs", [
      {
        url: this.localePath(
          `/${this.info.target.group.toLowerCase()}-converter`
        ),
        label: this.info.target.group + " " + this.$t("converter"),
      },
      {
        url: this.localePath(`/${this.info.target.slug}-converter`),
        label: this.info.target.name + " " + this.$t("converter"),
      },
    ]);
  },
  mounted() {
    if (this.info.targets) {
      this.targetList = [];
      for (const type in this.info.targets) {
        if (this.info.targets[type].length) {
          this.targetList.push({
            label: type,
            links: [...this.info.targets[type]]
              .sort((a, b) => (a.device ? 1 : 0) - (b.device ? 1 : 0))
              .map((v) => ({ label: v.name.toUpperCase(), value: v.name })),
          });
        }
      }
    }
  },
};
