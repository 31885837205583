class ConverterTemplateFactory {
  constructor () {
    this.template = null
  }

  setTemplate (template) {
    this.template = template
    return this
  }

  getTemplate () {
    return `${this.template}Template`
  }
}

export default new ConverterTemplateFactory()
