export default {
  head () {
    const meta = []
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    if (process.env.isStaging) {
      meta.push({ hid: 'robots', name: 'robots', content: 'noindex, nofollow' })
    }
    return {
      title: this.title,
      meta: [
        ...i18nHead.meta,
        ...meta,
        { hid: 'description', name: 'description', content: this.desc },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
        { hid: 'twitter:title', name: 'twitter:title', content: this.title },
        { hid: 'twitter:description', name: 'twitter:description', content: this.desc },
        { hid: 'og:title', property: 'og:title', content: this.title },
        { hid: 'og:description', property: 'og:description', content: this.desc },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:image', property: 'og:image', content: process.env.BASE_URL + '/img/logo.png' },
        { hid: 'og:image:alt', property: 'og:image:alt', content: 'freeconvert.com logo' },
        { hid: 'og:site_name', property: 'og:site_name', content: 'FreeConvert' },
        { hid: 'og:url', property: 'og:url', content: process.env.BASE_URL + this.localePath(this.currentUrl) }
      ],
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      link: [
        ...i18nHead.link
      ]
    }
  }
}
