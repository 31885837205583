import HttpService from '../HttpService'

/**
 * Fetches the FAQs based on selected params
 * @param {*} ctx 
 * @param {*} additionalParams 
 * @param {*} headers 
 * @returns 
 */
export async function fetchFaqs(ctx, additionalParams, headers) {
    const params = { ...additionalParams }
    if (ctx.$i18n && ctx.$i18n.locale && translate) params.lang = ctx.$i18n.locale;
    try {
        return HttpService.query().setPath('/view/faqs/').setParams(params).setHeaders(headers).get()
    } catch (e) {
    }
}

/**
 * Fetches the selected user reviews from TrustPilot
 * @param {*} translate | Whether to translate the content
 * @returns 
 */
export async function fetchReviews(ctx, translate = true) {
    const params = {}
    if (ctx.$i18n && ctx.$i18n.locale && translate) params.lang = ctx.$i18n.locale;
    try {
        return HttpService.query().setPath('/view/reviews/').setParams(params).get()
    } catch (e) {
    }
}

/**
 * Fetches details of an user review from TrustPilot by id
 * @param {*} id 
 * @param {*} translate | Whether to translate the content
 * @returns 
 */
export async function fetchReview(ctx, id, translate) {
    const params = {}
    if (ctx.$i18n && ctx.$i18n.locale && translate) params.lang = ctx.$i18n.locale
    try {
        const { data: info } = await HttpService.query().setPath('/view/reviews/' + id).setParams(params).get()
        return info
    } catch (e) {
    }
}
