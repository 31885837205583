// export function initiateAdsense () {
//   // (window.asc = window.asc || {"cmd":[]}).cmd.push(function(){
//   //   window.asc.processAdsOnPage();
//   // })
//   // (adsbygoogle = window.adsbygoogle || []).push({});
// }

// export function resetAdsense () {
//   // (window.asc = window.asc || {"cmd":[]}).cmd.push(function(){
//   //   window.asc.refresh();
//   //   window.asc.processAdsOnPage();
//   // });
//   // (adsbygoogle = window.adsbygoogle || []).push({});
// }
export function initiateAds() {
  (window.asc = window.asc || {"cmd":[]}).cmd.push(function(){
    window.asc.processAdsOnPage();
  })
}

export function resetAds() {
  (window.asc = window.asc || {"cmd":[]}).cmd.push(function(){
    window.asc.processAdsOnPage();
  });
}

export function resetSpecificAds(slots) {
  (window.asc = window.asc || {"cmd":[]}).cmd.push(function(){
    window.asc.processAdsOnPage(slots);
  });
}

export function destroyAds() {
  (window.asc = window.asc || {"cmd":[]}).cmd.push(function(){
    window.asc.destroy();
  });
}

export function destroySpecificAds(slots) {
  (window.asc = window.asc || {"cmd":[]}).cmd.push(function(){
    window.asc.destroyAdunits(slots);
  });
}